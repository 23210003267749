import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '..';
import { CheckDeliveryForm } from '..';
import classNames from 'classnames';

import DeliveryIcon from './DeliveryIcon';
import css from './CheckDeliveryModal.module.css';

const CheckDeliveryModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    onDeliveryAmountChange,
    onPickupAmountChange,

    // Check related props
    checkDeliveryInProgress,
    checkDeliveryError,
    perKm,
    listing,
    isDeliveryRequired,
  } = props;

  const classes = classNames('marketplaceModalBaseStyles', rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({
    id: 'Delivery.Later',
  });

  // Modal title, description and icon
  const modalTitle = <FormattedMessage id="Delivery.Title" />;
  const modalDescription = !isDeliveryRequired
    ? `Your Host does not offer pickup or delivery or has not specified a delivery price! Contact your host for more information.`
    : `Your host offers Pickup and Delivery for your rental at $${perKm} per km/mi. It is not required, but without, you will need to collect at the nearest pickup point.`;

  const modalIcon = <DeliveryIcon className={css.modalIcon} />;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {modalIcon}
      <p className="marketplaceModalTitleStyles">{modalTitle}</p>
      <p className="marketplaceModalParagraphStyles">{modalDescription}</p>
      <CheckDeliveryForm
        formId="CheckDeliveryForm"
        checkDeliveryInProgress={checkDeliveryInProgress}
        checkDeliveryError={checkDeliveryError}
        perKm={perKm}
        listing={listing}
        isDeliveryRequired={isDeliveryRequired}
        onSubmit={onSubmit}
        onDeliveryAmountChange={onDeliveryAmountChange}
        onPickupAmountChange={onPickupAmountChange}
      />
    </Modal>
  );
};

const { string, bool, number, func } = PropTypes;

CheckDeliveryModal.defaultProps = {
  className: null,
  rootClassName: null,
  checkDeliveryInProgress: false,
  checkDeliveryError: null,
  perKm: 0,
  listing: null,
  isDeliveryRequired: false,
};

CheckDeliveryModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string.isRequired,
  intl: intlShape.isRequired,
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  onDeliveryAmountChange: func.isRequired,
  onPickupAmountChange: func.isRequired,

  // Check related props
  checkDeliveryInProgress: bool,
  checkDeliveryError: string,
  perKm: number,
  listing: PropTypes.object,
  isDeliveryRequired: bool,
};

export default injectIntl(CheckDeliveryModal);