import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  InlineTextButton
} from '..';

import css from './CheckCoverageForm.module.css';

const CheckCoverageFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          invalid,
          handleSubmit,
          intl,
          checkCoverageInProgress,
          checkCoverageError,
          coverageAmount,
          isCoverageRequired,
        } = fieldRenderProps;

        //adding in coverage
        const coverageLabel = `I want to purchase AquaShield coverage for $${coverageAmount}.`;

        const errorMessage = checkCoverageError ? (
          <p className={css.error}>
            <FormattedMessage id="Coverage.Error" />
          </p>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = checkCoverageInProgress;
        const submitDisabled = invalid || submitInProgress;

        const termsLink = (
          <InlineTextButton 
            rootClassName={css.helperLink} 
            onClick={event => {
              event.preventDefault();
              window.open('https://wetrentals.com/terms-of-service#aquashield', '_blank');
            }}
          >
            <FormattedMessage id="Coverage.Link" />
          </InlineTextButton>
        );

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              // As handleSubmit is asynchronous function
              // we can await until it finishes and clear
              // the uplaoded files
              await handleSubmit(values, form);
            }}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <div className={css.coverageText}>
              <FormattedMessage 
                id="CheckCoverageForm.coverageText"
                values={{ termsLink }}
                className={css.coverageText}
              />
            </div>
            {isCoverageRequired ? null : (
              <div className={css.field}>
                <FieldCheckbox
                  className={css.field}
                  id={formId ? `${formId}.agreement` : 'agreement'}
                  name="agreement"
                  label={coverageLabel}
                />
              </div>
            )}
            {errorMessage}
            {isCoverageRequired ? null : (
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="CheckCoverageForm.submitButtonText" />
            </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

CheckCoverageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

CheckCoverageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckCoverageForm = compose(injectIntl)(CheckCoverageFormComponent);

CheckCoverageForm.displayName = 'CheckCoverageForm';

export default CheckCoverageForm;