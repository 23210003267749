import * as custom from './marketplace-custom-config.js';

// When addons are enabled, hosts will be able to add additional
// addons in EditListingPricingForm
//
// NOTE: If addons aren't enabled, it will only hide the addons
// from the EditListingPricingForm but previously added addons
// will still show up in the ListingPage
const enableAddons = true;

// When discounts are enabled, hosts will be able to add days
// discounts in EditListingPricingForm
//
// NOTE: If discounts aren't enabled, it will only hide the discounts
// from the EditListingPricingForm but previously added discounts
// will still show up in the ListingPage
const enableDiscounts = true;

// When day pricings are enabled, hosts will be able to add custom
// day-based pricings in EditListingPricingForm
//
// NOTE: If day pricings aren't enabled, it will only hide them
// from the EditListingPricingForm but previously added pricings
// will still show up in the ListingPage
const enableDayPricings = true;

// Enable location question
// If enabled, users will be asked to provide their location
const enableLocationQuestion = true;

// Enable dynamic pricing
// If enabled, users will be able to turn on dynamic pricing
const enableDynamicPricing = true;

// Enable commission promote
// If enabled, users will be able to promote their listings for a cost
const enableCommissionPromote = true;

// As a part of the CheckoutPage we ask user if he wants to
// purchase an additional coverage that is currently 10% of
// the total booking price.
const bookingCoverage = {
  enabled: true,
  percentage: 15,
};

// Enable listing location variants
// If enabled, users will be able add multiple location variants to their listings
const enableListingLocationVariant = true;

// Enable ShareTribe Hold
// If enabled, users listings who are not fareharbor will be removed. Login and signup will be disabled.
const enableHold = false;

// It should match one of the currencies listed in currency-config.js
const currencyConf = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;
const currency = currencyConf ? currencyConf.toUpperCase() : currencyConf;

// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  currency,
  custom,
  enableAddons,
  enableDiscounts,
  enableDayPricings,
  enableCommissionPromote,
  bookingCoverage,
  enableListingLocationVariant,
  enableLocationQuestion,
  enableDynamicPricing,
  enableHold
};

export default config;