import{ useEffect } from 'react';

const ClientRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  // Return null to avoid rendering anything on the server
  return null;
};

export default ClientRedirect;
