import React from 'react';

export default function IconCircleX({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height="48"
      width="48"
    >
      <g transform="scale(3.42857)">
        <circle cx="7" cy="7" r="6.5" fill="none" stroke="#000000" strokeWidth="1" />
        <line x1="4.5" y1="4.5" x2="9.5" y2="9.5" stroke="#000000" strokeWidth="1" />
        <line x1="9.5" y1="4.5" x2="4.5" y2="9.5" stroke="#000000" strokeWidth="1" />
      </g>
    </svg>
  );
}
