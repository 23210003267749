import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CheckDisputeModal.module.css';

const DisputeIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.DisputeIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="148"
      height="148"
      viewBox="0 0 50 50"
    >
    <path d="M9 12L11 14L15 9.99999M20 12C20 16.4611 14.54 19.6937 12.6414 20.683C12.4361 20.79 12.3334 20.8435 12.191 20.8712C12.08 20.8928 11.92 20.8928 11.809 20.8712C11.6666 20.8435 11.5639 20.79 11.3586 20.683C9.45996 19.6937 4 16.4611 4 12V8.21759C4 7.41808 4 7.01833 4.13076 6.6747C4.24627 6.37113 4.43398 6.10027 4.67766 5.88552C4.9535 5.64243 5.3278 5.50207 6.0764 5.22134L11.4382 3.21067C11.6461 3.13271 11.75 3.09373 11.857 3.07827C11.9518 3.06457 12.0482 3.06457 12.143 3.07827C12.25 3.09373 12.3539 3.13271 12.5618 3.21067L17.9236 5.22134C18.6722 5.50207 19.0465 5.64243 19.3223 5.88552C19.566 6.10027 19.7537 6.37113 19.8692 6.6747C20 7.01833 20 7.41808 20 8.21759V12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>

    </svg>
  );
};



const { string } = PropTypes;

DisputeIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

DisputeIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default DisputeIcon;
