import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldCheckbox, FieldTextInput, InlineTextButton } from '..';
import css from './CheckDisputeForm.module.css';

const CheckDisputeFormComponent = props => {
  const validateAmount = value => {
    return value >= 0 && value <= 200 ? undefined : <FormattedMessage id="CheckDisputeForm.invalidAmount" />;
  };
  const validateEntry = value => {
    return value ? undefined : <FormattedMessage id="CheckDisputeForm.invalidEntry" />;
  }

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          invalid,
          handleSubmit,
          intl,
          checkDisputeInProgress,
          checkDisputeError,
          disputeAmount,
          coverageReason,
          isDisputeRequired,
        } = fieldRenderProps;

        const errorMessage = checkDisputeError ? (
          <p className={css.error}>
            <FormattedMessage id="Dispute.Error" />
          </p>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = checkDisputeInProgress;
        const submitDisabled = invalid || submitInProgress;

        const termsLink = (
          <InlineTextButton
            rootClassName={css.helperLink}
            onClick={event => {
              event.preventDefault();
              window.open('https://wetrentals.com/terms-of-service#aquashield', '_blank');
            }}
          >
            <FormattedMessage id="Dispute.Link" />
          </InlineTextButton>
        );

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              await handleSubmit(values, form);
            }}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <div className={css.disputeText}>
              <FormattedMessage
                id="CheckDisputeForm.disputeText"
                values={{ termsLink }}
                className={css.disputeText}
              />
            </div>

            <div className={css.field}>
              <FieldTextInput
                className={css.field}
                id={formId ? `${formId}.dollarAmount` : 'dollarAmount'}
                name="dollarAmount"
                type="number"
                label="Enter a dollar amount ($0-$200)"
                validate={validateAmount}
                min="0"
                max="200"
                step="0.01"
              />
            </div>

            <div className={css.field}>
              <FieldTextInput
                className={css.field}
                id={formId ? `${formId}.reason` : 'reason'}
                name="reason"
                type="textarea"
                label="Reason for charge"
                placeholder="Please provide a reason for the charge"
                validate={validateEntry}
              />
            </div>

            <div className={css.field}>
              <FieldCheckbox
                className={css.field}
                id={formId ? `${formId}.holdDeposit` : 'holdDeposit'}
                name="holdDeposit"
                label="Check to hold deposit. You must contact WR to release funds."
              />
            </div>

            {errorMessage}

            {isDisputeRequired ? null : (
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="CheckDisputeForm.submitButtonText" />
              </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

CheckDisputeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

CheckDisputeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckDisputeForm = compose(injectIntl)(CheckDisputeFormComponent);

CheckDisputeForm.displayName = 'CheckDisputeForm';

export default CheckDisputeForm;
