import React from 'react';

export default function IconCircleQuestion({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill="none"
      height="48"
      width="48"
    >
      {/* Background circle */}
      <circle cx="24" cy="24" r="23" strokeWidth="4" fill="none" />
      {/* Question mark path */}
      <text x="50%" y="55%" textAnchor="middle" fontSize="35" fontFamily="Arial" dy=".3em">?</text>

    </svg>
  );
}
