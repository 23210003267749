import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import truncate from 'lodash/truncate';
import classNames from 'classnames';

import css from './ExpandableBio.module.css';

const BIO_COLLAPSED_LENGTH = 170;

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
    this.truncated = this.truncated.bind(this);
  }

  truncated = s => {
    return truncate(s, {
      length: this.props.bioCollapsedLength || BIO_COLLAPSED_LENGTH,

      // Allow truncated text end only in specific characters. This will
      // make the truncated text shorter than the length if the original
      // text has to be shortened and the substring ends in a separator.
      //
      // This ensures that the final text doesn't get cut in the middle
      // of a word.
      separator: /\s|,|\.|:|;/,
      omission: '…',
    });
  };

  render() {
    const { expand } = this.state;
    const { rootClassName, className, showMoreClassName, bio } = this.props;
    const classes = classNames(rootClassName || css.root, 'marketplaceH4FontStyles', className);
    const truncatedBio = this.truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const showMore = (
      <InlineTextButton
        rootClassName={classNames(
          'marketplaceH4FontStyles',
          'marketplaceLinkStyles',
          css.showMore,
          showMoreClassName
        )}
        onClick={handleShowMoreClick}
      >
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    return (
      <p className={classes}>
        {expand ? bio : truncatedBio}
        {bio !== truncatedBio && !expand ? showMore : null}
      </p>
    );
  }
}

ExpandableBio.defaultProps = {
  rootClassName: null,
  className: null,
  showMoreClassName: null,
};

ExpandableBio.propTypes = {
  rootClassName: string,
  className: string,
  showMoreClassName: string,
  bio: string.isRequired,
};

export default ExpandableBio;
