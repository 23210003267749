import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '..';
import { CheckCoverageForm } from '..';
import classNames from 'classnames';

import CoverageIcon from './CoverageIcon';
import css from './CheckCoverageModal.module.css';

const CheckCoverageModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,

    // Check related props
    checkCoverageInProgress,
    checkCoverageError,
    coverageAmount,
    isCoverageRequired,
  } = props;

  const classes = classNames('marketplaceModalBaseStyles', rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({
    id: 'Coverage.Later',
  });

  // Modal title, description and icon
  const modalTitle = 
    <FormattedMessage id="Coverage.Title" />;
  const modalDescription = isCoverageRequired ? `AquaShield Coverage for your rental is $${coverageAmount}. The host has made this a requirement and it has been charged already!` : `AquaShield Coverage for your rental will be $${coverageAmount}. It is optional, but highly recommended.`;

  const modalIcon =
    <CoverageIcon className={css.modalIcon} />;
  
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {modalIcon}
      <p className="marketplaceModalTitleStyles">{modalTitle}</p>
      <p className="marketplaceModalParagraphStyles">{modalDescription}</p>
      <CheckCoverageForm
        formId="CheckCoverageForm"
        checkCoverageInProgress={checkCoverageInProgress}
        checkCoverageError={checkCoverageError}
        coverageAmount={coverageAmount}
        isCoverageRequired={isCoverageRequired}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const { string } = PropTypes;

CheckCoverageModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CheckCoverageModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(CheckCoverageModal);