import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVeriff.module.css';

const IconVeriff = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fill: '#fff',
          fillOpacity: 1,
        }}
        d="m2.96 13.2 6.56 11.359 2.718-4.72c.16-.32.403-.558.723-.718.32-.16.64-.242.96-.242h5.438l-6 10.402c-.16.32-.398.559-.718.719-.32.16-.641.238-.961.238H7.36c-.32 0-.641-.078-.962-.238a1.563 1.563 0 0 1-.718-.719L.238 19.84A2.13 2.13 0 0 1 0 18.879c0-.32.078-.64.238-.957Zm0 0"
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fill: '#00b4aa',
          fillOpacity: 1,
        }}
        d="M22.559 5.68H9.519l2.72 4.718c.32.563.32 1.282 0 1.922l-2.72 4.72-6-10.4a2.13 2.13 0 0 1-.238-.96c0-.32.078-.64.239-.961L5.68.96c.16-.32.398-.563.718-.723C6.641.078 6.961 0 7.36 0h10.88c.64 0 1.28.32 1.6.96Zm0 0"
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fill: '#00b4aa',
          fillOpacity: 1,
        }}
        d="m19.36 26.398 6.562-11.359H20.48c-.32 0-.64-.078-.96-.238a1.571 1.571 0 0 1-.72-.723L16.079 9.36h12c.64 0 1.281.32 1.602.961l2.16 3.758c.16.32.238.64.238.961 0 .32-.078.64-.238.961l-5.442 9.52c-.16.32-.398.558-.718.718-.32.16-.559.16-.88.16Zm0 0"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconVeriff.defaultProps = {
  className: null,
  rootClassName: null,
};

IconVeriff.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVeriff;