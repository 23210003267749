import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';
import { types as sdkTypes } from './sdkLoader';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
const { Money } = sdkTypes;

// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse, sanitizeConfig = {}) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' && value?._ref?.type === 'imageAsset' && value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef = typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    listingData: listing,
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasDisplayName = user?.attributes?.profile?.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * Check if user has completed the Veriff verification.
 * User will be verified only if his verification status is
 * approved.
 *
 * @param {propTypes.user} user
 *
 * @return {String} boolean that declares if user is verified
 */
export const isUserVerified = user => {
  const hasUserFields = user?.attributes?.profile?.publicData;
  const hasVerification =
    hasUserFields && user.attributes.profile.publicData.verification;

  if (hasVerification) {
    return (
      user.attributes.profile.publicData.verification.status === 'approved'
    );
  } else {
    return false;
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * Generates a veriff verification payload. This payload is
 * only being used in the client side.
 *
 * @param {string} id session id
 * @param {string} url session url
 * @param {string} status veriff status
 *
 * @return {object} return verification object
 */
export const verificationPayload = (id, url, status) => {
  if (!id || !url || !status) {
    throw new Error('You need to pass id, url and status.');
  }

  const method = 'veriff';
  return {
    publicData: {
      veriffSessionId: id,
      verification: {
        method,
        status,
        sessionId: id,
        sessionUrl: url,
      },
    },
  };
};

/**
 * Gets the final listing price
 *
 * @param {array} price listing base price
 * @param {number} dayPricings dayPricings from publicData
 *
 * @return {object} return money object
 */
export const getListingPrice = (price, dayPricings) => {
  if (!dayPricings) {
    return price;
  }

  const minimumDayPricing = dayPricings.reduce(function(prev, curr) {
    return prev.price.amount < curr.price.amount ? prev : curr;
  });

  if (
    minimumDayPricing.price.amount === price.amount ||
    price.amount < minimumDayPricing.price.amount
  ) {
    return price;
  }

  return minimumDayPricing
    ? new Money(
        minimumDayPricing.price.amount,
        minimumDayPricing.price.currency
      )
    : price;
};

/**
 * Finds an applicable duration discount from
 * the array of discounts
 *
 * @param {array} discounts discounts array from publicData
 * @param {number} datesDayDifference difference between selected dates
 *
 * @return {object} return discount object
 */
export const findApplicableDiscount = (discounts, datesDayDifference) => {
  const denormalisedDiscounts = discounts?.map(d => ({
    duration: Number(d.duration),
    percentage: Number(d.percentage),
  }));

  const durationDiscounts = denormalisedDiscounts?.filter(
    d => d.duration <= datesDayDifference
  );
  const durationDiscount = durationDiscounts?.reduce(function(prev, current) {
    return prev.duration > current.duration ? prev : current;
  }, false);

  return durationDiscount;
};

/**
 * Generates an addons array of objects that can be used
 * as a form options
 *
 * @param {array} addons addons
 *
 * @return {array} return addons object
 */
export const denormaliseFormAddons = addons => {
  if (!addons) {
    throw new Error('You need to pass the addons.');
  }

  const denormalisedAddons = addons.map(a => {
    // We'll remove the description and price
    // from the addon object
    const { description, price, ...rest } = a;
    return {
      ...rest,
    };
  });

  return denormalisedAddons;
};

/**
 * Combines prices by day
 *
 * @param {array} bookingDates booking start and end date
 * @param {number} dayPricingsFromPublicData custom day pricings from publicData
 * @param {price} price listing price object
 *
 * @return {number} return total price number
 */
export const getDayBasedPricing = (
  bookingDates,
  dayPricingsFromPublicData,
  price,
  config
) => {
  // Get the range in days between the booking
  // start and end time
  const bookingDaysRange = moment.range(
    moment(bookingDates.startDate),
    moment(bookingDates.endDate).subtract(1, 'days')
  );
  const bookingDays = Array.from(bookingDaysRange.by('day')).map(r =>
    moment(r).format('dddd')
  );

  // Check if there are matching pricing days
  const hasMatchingDays = bookingDays
    .map(day => {
      const pricingDays = dayPricingsFromPublicData.map(a => a.day);
      return !!pricingDays.includes(day.toLowerCase());
    })
    .includes(true);
  if (!hasMatchingDays) {
    return null;
  }

  // Form pricing in days, in which the day that has a specific
  // price will be populated with the new price
  const pricingByDays = bookingDays.map(day => {
    const pricingDays = dayPricingsFromPublicData.map(a => a.day);
    const matchedPricingDay = dayPricingsFromPublicData.find(
      a => a.day === day.toLowerCase()
    );
    if (pricingDays.includes(day.toLowerCase())) {
      return {
        day: matchedPricingDay?.day,
        price: matchedPricingDay?.price.amount,
      };
    }
    return {
      day: day.toLowerCase(),
      price: price.amount,
    };
  });

  // Remove the last day from the array of days and
  // make sure to filter out the ones that returns null
  const filteredPricingByDays = pricingByDays.filter(d => d !== null);
  const hasFilteredPricingByDays = filteredPricingByDays?.length > 0;

  // Sum up the day prices
  const finalPricingAmountByDays =
    hasFilteredPricingByDays &&
    filteredPricingByDays
      .map(d => d.price)
      .reduce((prev, next) => prev + next, 0);

  return finalPricingAmountByDays
    ? new Money(finalPricingAmountByDays, price.currency)
    : null;
};

/**
 * Combines prices by date
 *
 * @param {array} bookingDates booking start and end date
 * @param {array} datePricingsFromPublicData custom date pricings from publicData
 * @param {price} price listing price object
 *
 * @return {number} return total price number
 */
export const getDateBasedPricing = (
  bookingDates,
  datePricingsFromPublicData,
  price
) => {
  // Get the range in days between the booking start and end time
  const bookingDaysRange = moment.range(
    moment(bookingDates.startDate),
    moment(bookingDates.endDate).subtract(1, 'days')
  );
  const bookingDays = Array.from(bookingDaysRange.by('day')).map(r =>
    moment(r).format('MM/DD/YYYY')
  );

  // Form pricing in dates, in which the date that has a specific
  // price will be populated with the new price
  const pricingByDates = bookingDays.map(date => {
    const matchedPricingDate = datePricingsFromPublicData.find(
      a => a.date === date
    );
    if (matchedPricingDate) {
      return {
        date: matchedPricingDate.date,
        price: matchedPricingDate.price.amount,
      };
    }
    return {
      date: date,
      price: price.amount,
    };
  });

  // Remove the last date from the array of dates and
  // make sure to filter out the ones that returns null
  const filteredPricingByDates = pricingByDates.filter(d => d !== null);
  const hasFilteredPricingByDates = filteredPricingByDates?.length > 0;

  // Sum up the date prices
  const finalPricingAmountByDates =
    hasFilteredPricingByDates &&
    filteredPricingByDates
      .map(d => d.price)
      .reduce((prev, next) => prev + next, 0);

  return finalPricingAmountByDates
    ? new Money(finalPricingAmountByDates, price.currency)
    : null;
};