import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { camelize } from '../../util/string';
export const ASSET_NAME = 'landing-page';

export const loadData = (params, search) => dispatch => {
  async function getUserLocation() {
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    const userIp = ipData.ip;
    const url = `https://ipwhois.app/json/${userIp}`;
    
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data.country); // country
      return data.country;
    } catch (error) {
      console.error('Failed to fetch IP geolocation:', error);
      return null;
    }
    }

  //getUserLocation();
  
  const pageAsset = { [camelize(ASSET_NAME)]: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};