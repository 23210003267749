import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleCheck, faAnchor, faCalendar, faCog, faUserGroup, faPlus, faLifeRing, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import config from '../../../config/config';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = config.enableHold ? null : (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = config.enableHold ? null : (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = config.enableHold ? null : (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    const integrateLink = ( 
      <NamedLink name="IntegratePage">
        <FormattedMessage id="TopbarMobileMenu.integrateLink" />
      </NamedLink>
    );

    const helpLink = (
      <NamedLink name="HostPage">
        <FormattedMessage id="TopbarMobileMenu.helpLink" />
      </NamedLink>
    );

    const faqLink = (
      <NamedLink name="SearchPage">
        <FormattedMessage id="TopbarMobileMenu.faqLink" />
      </NamedLink>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
        {config.enableHold ? null : (
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        )}
          <div className={css.authenticationLink}>
            <FormattedMessage
              id="TopbarMobileMenu.needBoat"
              values={{ lineBreak: <br />, faqLink }}
            />
          </div>
          <div className={css.authenticationLink}>
            <FormattedMessage
              id="TopbarMobileMenu.haveBoat"
              values={{ lineBreak: <br />, helpLink }}
            />
          </div>
          <div className={css.authenticationLink}>
            <FormattedMessage
              id="TopbarMobileMenu.integration"
              values={{ lineBreak: <br />, integrateLink}}
            />
          </div>
        </div>
        {config.enableHold ? null : (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
        ) }
        </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FontAwesomeIcon icon={faArrowLeft} />
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        > 
          <FontAwesomeIcon icon={faCalendar} />
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FontAwesomeIcon icon={faAnchor} />
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FontAwesomeIcon icon={faUser} />
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('VerificationPage'))}
          name="VerificationPage"
        >
          <FontAwesomeIcon icon={faCircleCheck} />
          <FormattedMessage id="TopbarMobileMenu.verificationPage" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FontAwesomeIcon icon={faCog} />
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        {currentUser?.attributes.stripeConnected ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('HostFacebookPage'))}
            name="HostFacebookPage"
          >
            <FontAwesomeIcon icon={faUserGroup} />
            <FormattedMessage id="TopbarDesktop.facebookGroup" />
          </NamedLink>
        ):(
          <NamedLink
          className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}
          name="NewListingPage"
        >
          <FontAwesomeIcon icon={faPlus} />
          <FormattedMessage id="TopbarDesktop.createFirstListing" />
        </NamedLink>

        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('HelpPage'))}
          name="HelpPage"
        >
          <FontAwesomeIcon icon={faLifeRing} />
          <FormattedMessage id="TopbarDesktop.getHelp" />
        </NamedLink>

        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
