import { createVeriffSessionAPI } from '../../util/api';
import { storableError } from '../../util/errors';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { verificationPayload } from '../../util/data';
import { STATUS_STARTED, STATUS_APPROVED, STATUS_DECLINED } from '../../util/verification';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/VerificationPage/SET_INITIAL_STATE';

export const CREATE_VERIFICATION_REQUEST = 'app/VerificationPage/CREATE_VERIFICATION_REQUEST';
export const CREATE_VERIFICATION_SUCCESS = 'app/VerificationPage/CREATE_VERIFICATION_SUCCESS';
export const CREATE_VERIFICATION_ERROR = 'app/VerificationPage/CREATE_VERIFICATION_ERROR';

export const UPDATE_VERIFICATION_REQUEST = 'app/VerificationPage/UPDATE_VERIFICATION_REQUEST';
export const UPDATE_VERIFICATION_SUCCESS = 'app/VerificationPage/UPDATE_VERIFICATION_SUCCESS';
export const UPDATE_VERIFICATION_ERROR = 'app/VerificationPage/UPDATE_VERIFICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  sessionId: null,
  sessionUrl: null,

  // Create verification
  createVerificationInProgress: false,
  createVerificationError: null,

  // Update verification
  updateVerificationInProgress: false,
  updateVerificationError: null,
};

export default function verificationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case CREATE_VERIFICATION_REQUEST:
      return { ...state, createVerificationInProgress: true };
    case CREATE_VERIFICATION_SUCCESS:
      return {
        ...state,
        sessionId: payload.id,
        sessionUrl: payload.url,
        createVerificationInProgress: false,
      };
    case CREATE_VERIFICATION_ERROR:
      return { ...state, createVerificationError: payload };

    case UPDATE_VERIFICATION_REQUEST:
      return {
        ...state,
        updateVerificationInProgress: true,
      };
    case UPDATE_VERIFICATION_SUCCESS:
      return {
        ...state,
        updateVerificationInProgress: false,
      };
    case UPDATE_VERIFICATION_ERROR:
      return {
        ...state,
        updateVerificationInProgress: false,
        updateVerificationError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

// Create verification
export const createVerificationRequest = () => ({
  type: CREATE_VERIFICATION_REQUEST,
});
export const createVerificationSuccess = responseData => ({
  type: CREATE_VERIFICATION_SUCCESS,
  payload: {
    id: responseData.id,
    url: responseData.url,
  },
});
export const createVerificationError = e => ({
  type: CREATE_VERIFICATION_ERROR,
  error: true,
  payload: e,
});

// Update verification
export const updateVerificationRequest = () => ({
  type: UPDATE_VERIFICATION_REQUEST,
});
export const updateVerificationSuccess = () => ({
  type: UPDATE_VERIFICATION_SUCCESS,
});
export const updateVerificationError = e => ({
  type: UPDATE_VERIFICATION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const createVerification = () => async (dispatch, getState) => {
  dispatch(createVerificationRequest());

  const { currentUser } = getState().user;
  const { firstName, lastName } = currentUser.attributes.profile;

  // Veriff expects person object
  const body = { person: { firstName, lastName, id: currentUser.id.uuid } };

  try {
    const response = await createVeriffSessionAPI(body);
    const responseData = response.data;

    // Create verification payload
    // for the current user
    const actionPayload = verificationPayload(responseData.id, responseData.url, STATUS_STARTED);

    dispatch(updateProfile(actionPayload));
    dispatch(createVerificationSuccess(responseData));

    // Only return the session url
    return responseData.url;
  } catch (e) {
    return dispatch(createVerificationError(storableError(e)));
  }
};

export const updateVerification = status => async (dispatch, getState) => {
  dispatch(updateVerificationRequest());

  const { currentUser } = getState().user;
  const { veriffSessionId, verification } = currentUser.attributes.profile.publicData;

  const veriffSessionUrl = verification.sessionUrl;
  const currentVerificationStatus = verification.status;

  if (
    currentVerificationStatus === STATUS_APPROVED ||
    currentVerificationStatus === STATUS_DECLINED
  ) {
    return null;
  }

  // Update verification payload
  // for the current user
  const actionPayload = verificationPayload(veriffSessionId, veriffSessionUrl, status);

  try {
    const result = await dispatch(updateProfile(actionPayload));
    return result;
  } catch (e) {
    return dispatch(updateVerificationError(storableError(e)));
  }
};
