import React from 'react';
import { string, node, bool, oneOfType } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconVerified } from '../../components';

import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import css from './VerifiedBadge.module.css';

const tooltipId = 'VerifiedBadge.tooltip';

const VerifiedBadge = props => {
  const {
    rootClassName,
    className,
    iconClassName,
    tooltipMessage,
    withText,
  } = props;
  const classes = classNames(rootClassName || className, css.root);
  const iconClasses = classNames(iconClassName || css.verifiedIcon);

  if (!tooltipMessage) {
    throw new Error(
      "You need to pass tooltipMessage. If you don't want to render tooltip use <IconVerified /> instead."
    );
  }
  return (
    <div className={classes}>
      <div
        className={css.iconWrapper}
        data-for={tooltipId}
        data-tip={tooltipMessage}
      >
        <IconVerified className={iconClasses} />
        <ReactTooltip
          className={css.tooltip}
          id={tooltipId}
          getContent={() => {
            // This will ensure that the tooltip
            // also renders HTML
            return tooltipMessage;
          }}
        />
      </div>
      {withText ? (
        <div className={css.text}>
          <FormattedMessage id="VerifiedBadge.verifiedText" />
        </div>
      ) : null}
    </div>
  );
};

VerifiedBadge.defaultProps = {
  className: null,
  rootClassName: null,
  iconClassName: null,
  tooltipMessage: null,
  withText: false,
};

VerifiedBadge.propTypes = {
  className: string,
  rootClassName: string,
  iconClassName: string,
  tooltipMessage: oneOfType([string, node]),
  withText: bool,
};

export default VerifiedBadge;