import React from 'react';
import PropTypes from 'prop-types';
import { BOAT_CHECKOUT } from '../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '..';
import { CheckBoatForm } from '../';
import classNames from 'classnames';

import CheckoutIcon from './CheckoutIcon';
import CheckinIcon from './CheckinIcon';
import css from './CheckBoatModal.module.css';

const CheckBoatModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,

    // Check related props
    checkBoatType,
    checkBoatInProgress,
    checkBoatError,
  } = props;

  const classes = classNames('marketplaceModalBaseStyles', rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({
    id: 'CheckBoatModal.later',
  });

  const isCheckout = checkBoatType === BOAT_CHECKOUT;

  // Modal title, description and icon
  const modalTitle = isCheckout ? (
    <FormattedMessage id="CheckBoatModal.checkoutTitle" />
  ) : (
    <FormattedMessage id="CheckBoatModal.checkinTitle" />
  );
  const modalDescription = isCheckout ? (
    <FormattedMessage id="CheckBoatModal.checkoutDescription" />
  ) : (
    <FormattedMessage id="CheckBoatModal.checkinDescription" />
  );
  const modalIcon = isCheckout ? (
    <CheckoutIcon className={css.modalIcon} />
  ) : (
    <CheckinIcon className={css.modalIcon} />
  );

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {modalIcon}
      <p className="marketplaceModalTitleStyles">{modalTitle}</p>
      <p className="marketplaceModalParagraphStyles">{modalDescription}</p>
      <CheckBoatForm
        formId="CheckBoatForm"
        checkBoatInProgress={checkBoatInProgress}
        checkBoatError={checkBoatError}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const { string } = PropTypes;

CheckBoatModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CheckBoatModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  checkBoatType: string,
};

export default injectIntl(CheckBoatModal);
