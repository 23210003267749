import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';

export const STATUS_STARTED = 'started';
export const STATUS_IN_PROGRESS = 'in-progress';
export const STATUS_APPROVED = 'approved';
export const STATUS_DECLINED = 'declined';

export const onOpenVeriffModal = async (
  onCreateVerification,
  onUpdateVerification,
  onFetchCurrentUser,
  sessionUrl
) => {
  // First await the Veriff session url which runs
  // sessions API in VerificationPage.duck and returns
  // session url
  const veriffSessionUrl = sessionUrl
    ? sessionUrl
    : await onCreateVerification();

  // Open inContext Veriff Frame
  return createVeriffFrame({
    // Veriff will throw an error if session url
    // isn't provided
    url: veriffSessionUrl,
    onEvent: async msg => {
      switch (msg) {
        case MESSAGES.FINISHED:
          await onFetchCurrentUser();
          return onUpdateVerification(STATUS_IN_PROGRESS);
      }
    },
  });
};
