/**
 * Functional component to help with uploading
 * multiple images/files
 *
 * See: https://react-dropzone.js.org
 */
import React, { useRef, useEffect } from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import { IconClose } from '../../components';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import css from './FieldDropzone.module.css';

const ACCEPT_IMAGES = 'image/*';

const Thumb = props => {
  const { file, removeFile, disabled } = props;
  return (
    <li className={css.thumb} key={file.name}>
      <span
        className={css.removeContainer}
        onClick={() => {
          if (!disabled) {
            removeFile(file.index);
          }
        }}
      >
        <IconClose className={css.removeIcon} />
      </span>
      <div className={css.thumbInner}>
        <img src={file.preview} className={css.thumbImage} />
      </div>
    </li>
  );
};

const FieldDropzoneComponent = props => {
  const isMounted = useRef(false);
  const { input, disabled, files, setFiles } = props;

  if (!files || !setFiles) {
    throw new Error('files and setFiles props are required');
  }

  useEffect(() => {
    // We need to avoid running onChange
    // function on first mount
    if (isMounted.current) {
      input.onChange(files);
    } else {
      isMounted.current = true;
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPT_IMAGES,
    disabled,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            index,
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeFile = fileIndex => {
    const newFiles = files.filter(f => f.index !== fileIndex);
    setFiles(newFiles);
  };

  const renderThumbs = files.map(file => {
    return (
      <Thumb
        key={file.index}
        file={file}
        disabled={disabled}
        removeFile={removeFile}
      />
    );
  });

  const renderThumbsContainer =
    files.length > 0 ? (
      <ul className={css.thumbsContainer}>{renderThumbs}</ul>
    ) : null;

  const browseMessage = (
    <span className={css.browseMessage}>
      <FormattedMessage id="FieldDropzone.browseMessage" />
    </span>
  );

  const addImagesWrapperClasses = classNames(css.addImagesWrapper, {
    [css.addImagesWrapperDisabled]: disabled,
  });

  return (
    <div className={css.addImages}>
      <div className={addImagesWrapperClasses} {...getRootProps()}>
        <input {...getInputProps()} />
        <p className={css.addImagesMessage}>
          <FormattedMessage
            id="FieldDropzone.addImagesMessage"
            values={{ browseMessage }}
          />
        </p>
      </div>
      {renderThumbsContainer}
    </div>
  );
};

const FieldDropzone = props => {
  const { rootClassName, className, id, label, disabled, ...rest } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const inputProps = { id, ...rest };
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {label ? (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <Field
        component={FieldDropzoneComponent}
        disabled={disabled}
        {...inputProps}
      />
    </div>
  );
};

FieldDropzone.defualtProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

FieldDropzone.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,
};

export default FieldDropzone;
