import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CheckBoatModal.module.css';

const CheckoutIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.checkoutIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      width="48"
      viewBox="0 0 48 48"
    >
      <path
        d="M2.5 13.5h-1a1 1 0 0 1-1-1v-8h13v8a1 1 0 0 1-1 1h-1"
        transform="scale(3.42857)"
      />
      <path
        d="M4.5 11 7 13.5 9.5 11M7 13.5v-6M11.29 1a1 1 0 0 0-.84-.5h-6.9a1 1 0 0 0-.84.5L.5 4.5h13ZM7 .5v4"
        transform="scale(3.42857)"
      />
    </svg>
  );
};

const { string } = PropTypes;

CheckoutIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

CheckoutIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default CheckoutIcon;