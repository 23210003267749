import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCircleX, NamedLink } from '..';

import css from './ModalMissingInformation.module.css';

const PhoneReminder = props => {
  const {
    className,
    user,
  } = props;


  const link = (
    <NamedLink name="ContactDetailsPage">
      <FormattedMessage id="ModalMissingInformation.fixPhoneText" />
    </NamedLink>
  );

  return (
    <div className={className}>
      <IconCircleX className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.missingPhoneTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.missingPhoneText" />
      </p>
      <div className={css.bottomWrapper}>
   
          <FormattedMessage
            id="ModalMissingInformation.changePhone"
            values={{ link }}
          />
     
      </div>
    </div>
  );
};

export default PhoneReminder;
