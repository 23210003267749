import { denormalisedResponseEntities } from '../../util/data';
import { decodeLatLngBounds } from '../../util/urlHelpers';

export const listingsPerPage = 12;

export const SET_LISTINGS = 'app/DynamicPage/SET_LISTINGS';
export const SET_BOUNDS = 'app/DynamicPage/SET_BOUNDS';
export const SET_LOADING_LISTINGS = 'app/DynamicPage/SET_LOADING_LISTINGS';
export const SET_LOADING_BOUNDS = 'app/DynamicPage/SET_LOADING_BOUNDS';

const initialState = {
  listings: [],
  meta: {},
  bounds: null,
  loadingListings: false,
  loadingBounds: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_LISTINGS:
      return { ...state, ...payload };
    case SET_BOUNDS:
      return { ...state, ...payload };
    case SET_LOADING_LISTINGS:
      return { ...state, ...payload };
    case SET_LOADING_BOUNDS:
      return { ...state, ...payload };
    default:
      return state;
  }
}

// TODO: Generate these default params in a single place instead of just copying them here
const defaultFetchParams = {
  mapSearch: true,
  page: 1,
  sort: 'pub_promote',
  perPage: listingsPerPage,
  include: ['author', 'images'],
  'fields.listing': [
    'title',
    'geolocation',
    'price',
    'publicData.listingType',
    'publicData.transactionProcessAlias',
    'publicData.unitType',
    'publicData.promoteCommission',
    'publicData.location',
    'publicData.promote',
    'publicData.promotedOn',
    'publicData.WR',
    'publicData.pickupEnabled',
    'publicData.shippingEnabled',
    'publicData.mainListingId',
    'publicData.isLocationVariant',
  ],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
  'fields.image': [
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.listing-card',
    'variants.listing-card-2x',
  ],
  'imageVariant.listing-card': 'w:400;h:300;fit:crop',
  'imageVariant.listing-card-2x': 'w:800;h:600;fit:crop',
  'limit.images': 1,
};

export const fetchListings = params => async (dispatch, getState, sdk) => {
  dispatch({ type: SET_LOADING_LISTINGS, payload: { loadingListings: true } });
  try {
    const mergedParams = { ...defaultFetchParams, ...params };

    const result = await sdk.listings.query(mergedParams);

    const listings = denormalisedResponseEntities(result);

    const payload = { listings, meta: result.data.meta };

    dispatch({
      type: SET_LISTINGS,
      payload: payload,
    });

    return payload;
  } catch (error) {
    console.error('Error fetching listings:', error);
    throw new Error('Error fetching listings');
  } finally {
    dispatch({ type: SET_LOADING_LISTINGS, payload: { loadingListings: false } });
  }
};

export const setBounds = config => async dispatch => {
  dispatch({ type: SET_LOADING_BOUNDS, payload: { loadingBounds: true } });
  try {
    const pathname = window.location.pathname.toLowerCase();
    const listingsLocation = pathname.replace('/destinations/', '').replace('/boat-rentals', '');
    const boundingBox = await getBoundingBox(listingsLocation, config.maps.mapboxAccessToken);

    const bounds = decodeLatLngBounds(boundingBox.join(','));

    dispatch({
      type: SET_BOUNDS,
      payload: { bounds },
    });

    return bounds;
  } catch (error) {
    console.error('Error setting bounds:', error);
    throw new Error('Error setting bounds');
  } finally {
    dispatch({ type: SET_LOADING_BOUNDS, payload: { loadingBounds: false } });
  }
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  try {
    const bounds = await dispatch(setBounds(config));

    const fetchParams = {
      ...params,
      bounds,
      page: 1,
    };

    await dispatch(fetchListings(fetchParams));
  } catch (error) {
    console.error('Error loading data:', error);
    throw new Error('Error loading data');
  }
};

const getBoundingBox = async (location, accessToken) => {
  try {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?access_token=${accessToken}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch location data');
    }
    const data = await response.json();
    return data.features[0].bbox.reverse();
  } catch (error) {
    console.error('Error fetching bounding box:', error);
    throw new Error('No results found');
  }
};
