import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'canada',
    predictionPlace: {
      address: 'Canada',
      bounds: new LatLngBounds(new LatLng(75.02020086, -65.5414447), new LatLng(30.22887485, -126.32320204)),
    },
  },
  {
    id: 'usa',
    predictionPlace: {
      address: 'USA',
      bounds: new LatLngBounds(new LatLng(80.0446411, -59.21089166), new LatLng(-21.64510077, -172.22612606)),
    },
  },
  {
    id: 'costa-rica',
    predictionPlace: {
      address: 'Costa Rica',
      bounds: new LatLngBounds(new LatLng(13.7418966, -80.32691513), new LatLng(2.98181465, -87.9246348)),
    },
  },
  {
    id: 'europe',
    predictionPlace: {
      address: 'Europe',
      bounds: new LatLngBounds(new LatLng(70.83090794, 37.88574868), new LatLng(20.94169873, -18.25589478)),
    },
  },
  {
    id: 'central-america',
    predictionPlace: {
      address: 'Central America',
      bounds: new LatLngBounds(new LatLng(44.21937086, -52.78098286), new LatLng(-29.72927027, -108.97574251)),
    },
  },
];
export default defaultLocations;
