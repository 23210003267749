import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldLocationAutocompleteInput,
  FieldDropzone,
  Modal,
} from '../../components';
import {
  requiredAndNonEmptyString,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  nonEmptyArray,
} from '../../util/validators';

import css from './CheckBoatForm.module.css';

const identity = v => v;

const CheckBoatFormComponent = props => {
  const [files, setFiles] = useState([]);
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          values,
          form,
          invalid,
          handleSubmit,
          intl,
          checkBoatInProgress,
          checkBoatError,
        } = fieldRenderProps;

        //adding in damage field
        const damageLabel = intl.formatMessage({
          id: 'damageBoatForm.damageLabel',
        });
        const damagePlaceholder = intl.formatMessage({
          id: 'damageBoatForm.damagePlaceholder',
        });
        const damageRequiredMessage = intl.formatMessage({
          id: 'damageBoatForm.damageRequired',
        });

        const damageRequired = requiredAndNonEmptyString(damageRequiredMessage);

        //adding in hours indicator
        const hoursLabel = intl.formatMessage({
          id: 'hoursBoatForm.hoursLabel',
        });
        const hoursPlaceholder = intl.formatMessage({
          id: 'hoursBoatForm.hoursPlaceholder',
        });
        const hoursRequiredMessage = intl.formatMessage({
          id: 'hoursBoatForm.hoursRequired',
        });

        const hoursRequired = requiredAndNonEmptyString(hoursRequiredMessage);

        //adding in gas level
        const levelLabel = intl.formatMessage({
          id: 'levelBoatForm.levelLabel',
        });
        const levelPlaceholder = intl.formatMessage({
          id: 'levelBoatForm.levelPlaceholder',
        });
        const levelRequiredMessage = intl.formatMessage({
          id: 'levelBoatForm.levelRequired',
        });

        const levelRequired = requiredAndNonEmptyString(levelRequiredMessage);

        const notesLabel = intl.formatMessage({
          id: 'CheckBoatForm.notesLabel',
        });
        const notesPlaceholder = intl.formatMessage({
          id: 'CheckBoatForm.notesPlaceholder',
        });
        const notesRequiredMessage = intl.formatMessage({
          id: 'CheckBoatForm.notesRequired',
        });

        const notesRequired = requiredAndNonEmptyString(notesRequiredMessage);

        const locationLabel = intl.formatMessage({
          id: 'CheckBoatForm.locationLabel',
        });
        const locationPlaceholder = intl.formatMessage({
          id: 'CheckBoatForm.locationPlaceholder',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'CheckBoatForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'CheckBoatForm.addressNotRecognized',
        });

        const imagesLabel = intl.formatMessage({
          id: 'CheckBoatForm.imagesLabel',
        });
        const imagesRequiredMessage = intl.formatMessage({
          id: 'CheckBoatForm.imagesRequired',
        });
        const imagesRequired = nonEmptyArray(imagesRequiredMessage);

        const errorMessage = checkBoatError ? (
          <p className={css.error}>
            <FormattedMessage id="CheckBoatForm.checkBoatError" />
          </p>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = checkBoatInProgress;
        const imagesDisabled = checkBoatInProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              // As handleSubmit is asynchronous function
              // we can await until it finishes and clear
              // the uplaoded files
              await handleSubmit(values, form);
              setFiles([]);
            }}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <FieldTextInput
              className={css.field}
              type="textarea"
              name="damage"
              id={formId ? `${formId}.damage` : 'damage'}
              label={damageLabel}
              placeholder={damagePlaceholder}
              validate={damageRequired}
            />

            <FieldTextInput
              className={css.field}
              type="textarea"
              name="hours"
              id={formId ? `${formId}.hours` : 'hours'}
              label={hoursLabel}
              placeholder={hoursPlaceholder}
              validate={hoursRequired}
            />

            <FieldTextInput
              className={css.field}
              type="textarea"
              name="level"
              id={formId ? `${formId}.level` : 'level'}
              label={levelLabel}
              placeholder={levelPlaceholder}
              validate={levelRequired}
            />

            <FieldTextInput
              className={css.field}
              type="textarea"
              name="notes"
              id={formId ? `${formId}.notes` : 'notes'}
              label={notesLabel}
              placeholder={notesPlaceholder}
              validate={notesRequired}
            />
            <FieldLocationAutocompleteInput
              rootClassName={css.locationAddressWrapper}
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={locationLabel}
              placeholder={locationPlaceholder}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldDropzone
              className={css.imagesField}
              id="images"
              name="images"
              label={imagesLabel}
              disabled={imagesDisabled}
              validate={imagesRequired}
              files={files}
              setFiles={setFiles}
            />

            {errorMessage}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="CheckBoatForm.submitButtonText" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

CheckBoatFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

CheckBoatFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckBoatForm = compose(injectIntl)(CheckBoatFormComponent);

CheckBoatForm.displayName = 'CheckBoatForm';

export default CheckBoatForm;
