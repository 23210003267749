import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '..';
import { CheckTowForm } from '..';
import classNames from 'classnames';

import TowIcon from './TowIcon';
import css from './CheckTowModal.module.css';

const CheckTowModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,

    // Check related props
    checkTowInProgress,
    checkTowError,
    towAmount,
  } = props;

  const classes = classNames('marketplaceModalBaseStyles', rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({
    id: 'Tow.Later',
  });

  // Modal title, description and icon
  const modalTitle = 
    <FormattedMessage id="Tow.Title" />;
  const modalDescription = `AquaTow for your rental will be $${towAmount}. It is optional, but highly recommended. If you don't submit this form, you will be charged automatically the day of your rental.`;

  const modalIcon =
    <TowIcon className={css.modalIcon} />;
  
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {modalIcon}
      <p className="marketplaceModalTitleStyles">{modalTitle}</p>
      <p className="marketplaceModalParagraphStyles">{modalDescription}</p>
      <CheckTowForm
        formId="CheckTowForm"
        checkTowInProgress={checkTowInProgress}
        checkTowError={checkTowError}
        towAmount={towAmount}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const { string } = PropTypes;

CheckTowModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CheckTowModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(CheckTowModal);