import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  InlineTextButton
} from '..';

import css from './CheckTowForm.module.css';

const CheckTowFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          invalid,
          handleSubmit,
          intl,
          checkTowInProgress,
          checkTowError,
          towAmount,
        } = fieldRenderProps;

        //adding in tow
        const towLabel = `I want to purchase AquaTow for $${towAmount}.`;

        const errorMessage = checkTowError ? (
          <p className={css.error}>
            <FormattedMessage id="Tow.Error" />
          </p>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = checkTowInProgress;
        const submitDisabled = invalid || submitInProgress;

        const termsLink = (
          <InlineTextButton 
            rootClassName={css.helperLink} 
            onClick={event => {
              event.preventDefault();
              window.open('https://wetrentals.com/terms-of-service#aquatow', '_blank');
            }}
          >
            <FormattedMessage id="Tow.Link" />
          </InlineTextButton>
        );

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              // As handleSubmit is asynchronous function
              // we can await until it finishes and clear
              // the uplaoded files
              await handleSubmit(values, form);
            }}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <div className={css.towText}>
              <FormattedMessage 
                id="CheckTowForm.towText"
                values={{ termsLink }}
                className={css.towText}
              />
            </div>
              <div className={css.field}>
                <FieldCheckbox
                  className={css.field}
                  id={formId ? `${formId}.agreement` : 'agreement'}
                  name="agreement"
                  label={towLabel}
                />
              </div>
            {errorMessage}
            
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="CheckTowForm.submitButtonText" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

CheckTowFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

CheckTowFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckTowForm = compose(injectIntl)(CheckTowFormComponent);

CheckTowForm.displayName = 'CheckTowForm';

export default CheckTowForm;
