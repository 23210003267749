import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  SecondaryButton,
  FieldCheckbox,
  InlineTextButton,
  FieldLocationAutocompleteInput,
} from '..';

import css from './CheckDeliveryForm.module.css';

const identity = v => v;

const CheckDeliveryFormComponent = props => {
  const [deliveryDistance, setDeliveryDistance] = useState(null);
  const [pickupDistance, setPickupDistance] = useState(null);

  const calculateDistance = async (location1, location2) => {
    const proxyUrl = `https://wetrentals.com/api/calculate-distance?location1=${encodeURIComponent(location1)}&location2=${encodeURIComponent(location2)}`;

    try {
      const response = await fetch(proxyUrl);
      const data = await response.json();

      if (data.distance !== undefined) {
        return data.distance;
      } else {
        throw new Error('Failed to calculate distance');
      }
    } catch (error) {
      console.error('Error calculating distance:', error);
      return null;
    }
  };

  const findClosestLocation = async (targetLocation, locations) => {
    let shortestDistance = Infinity;

    for (const loc of locations) {
      const address = loc.address || loc.location?.address;
      if (address) {
        const distance = await calculateDistance(targetLocation, address);
        if (distance < shortestDistance) {
          shortestDistance = distance;
        }
      }
    }

    return shortestDistance;
  };

  const handleCalculateDelivery = async (values, listing, perKm) => {
    const deliveryLocation = values.deliveryLocation?.selectedPlace?.address;
    const mainLocation = listing.attributes.publicData.location.address;
    const additionalLocations = listing.attributes.publicData.additionalLocations || [];

    if (deliveryLocation) {
      const shortestDistance = await findClosestLocation(deliveryLocation, [
        { address: mainLocation },
        ...additionalLocations,
      ]);

      const deliveryAmount = shortestDistance * perKm * 2;
      setDeliveryDistance(deliveryAmount);
      props.onDeliveryAmountChange(deliveryAmount);
    }
  };

  const handleCalculatePickup = async (values, listing, perKm) => {
    const pickupLocation = values.pickupLocation?.selectedPlace?.address;
    const mainLocation = listing.attributes.publicData.location.address;
    const additionalLocations = listing.attributes.publicData.additionalLocations || [];

    if (pickupLocation) {
      const shortestDistance = await findClosestLocation(pickupLocation, [
        { address: mainLocation },
        ...additionalLocations,
      ]);

      const pickupAmount = shortestDistance * perKm * 2;
      setPickupDistance(pickupAmount);
      props.onPickupAmountChange(pickupAmount);
    }
  };

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          values,
          invalid,
          handleSubmit,
          intl,
          checkDeliveryInProgress,
          checkDeliveryError,
          perKm,
          listing,
          isDeliveryRequired,
        } = fieldRenderProps;

        const deliveryLabel = deliveryDistance !== null
          ? `I want to purchase delivery for $${deliveryDistance.toFixed(2)}.`
          : "Calculate delivery cost.";
        const pickupLabel = pickupDistance !== null
          ? `I want to purchase pickup for $${pickupDistance.toFixed(2)}.`
          : "Calculate pickup cost.";

        const errorMessage = checkDeliveryError ? (
          <p className={css.error}>
            <FormattedMessage id="Delivery.Error" />
          </p>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = checkDeliveryInProgress;
        const submitDisabled = invalid || submitInProgress;

        const termsLink = (
          <InlineTextButton
            rootClassName={css.helperLink}
            onClick={event => {
              event.preventDefault();
              window.open('https://wetrentals.com/terms-of-service#delivery', '_blank');
            }}
          >
            <FormattedMessage id="Delivery.Link" />
          </InlineTextButton>
        );

        const deliveryLocationLabel = (
          <FormattedMessage
            id="CheckDeliveryForm.deliveryText"
            values={{ termsLink }}
            className={css.deliveryText}
          />
        );
        const deliveryLocationPlaceholder = intl.formatMessage({
          id: 'CheckDeliveryForm.deliveryLocationPlaceholder',
        });
        const pickupLocationLabel = (
          <FormattedMessage
            id="CheckDeliveryForm.pickupText"
            values={{ termsLink }}
            className={css.deliveryText}
          />
        );
        const pickupLocationPlaceholder = intl.formatMessage({
          id: 'CheckDeliveryForm.pickupLocationPlaceholder',
        });

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            {isDeliveryRequired && (
              <>
                <div className={css.section}>
                  <h3 className={css.sectionTitle}>Delivery</h3>
                  <FieldLocationAutocompleteInput
                    rootClassName={css.locationAddressWrapper}
                    className={css.locationAddress}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="deliveryLocation"
                    label={deliveryLocationLabel}
                    placeholder={deliveryLocationPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.deliveryLocation}
                  />
                  <SecondaryButton
                    type="button"
                    onClick={() => handleCalculateDelivery(values, listing, perKm)}
                    className={css.calculateButton}
                  >
                    Calculate Delivery
                  </SecondaryButton>
                  <div className={css.field}>
                    <FieldCheckbox
                      className={css.field}
                      id={formId ? `${formId}.isDelivery` : 'isDelivery'}
                      name="isDelivery"
                      label={deliveryLabel}
                    />
                  </div>
                </div>
                <hr className={css.divider} />
                <div className={css.section}>
                  <h3 className={css.sectionTitle}>Pickup</h3>
                  <FieldLocationAutocompleteInput
                    rootClassName={css.locationAddressWrapper}
                    className={css.locationAddress}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="pickupLocation"
                    label={pickupLocationLabel}
                    placeholder={pickupLocationPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.pickupLocation}
                  />
                  <SecondaryButton
                    type="button"
                    onClick={() => handleCalculatePickup(values, listing, perKm)}
                    className={css.calculateButton}
                  >
                    Calculate Pickup
                  </SecondaryButton>
                  <div className={css.field}>
                    <FieldCheckbox
                      className={css.field}
                      id={formId ? `${formId}.isPickup` : 'isPickup'}
                      name="isPickup"
                      label={pickupLabel}
                    />
                  </div>
                </div>
              </>
            )}
            {errorMessage}
            {isDeliveryRequired && (
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="CheckDeliveryForm.submitButtonText" />
              </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

CheckDeliveryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

CheckDeliveryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  onDeliveryAmountChange: func.isRequired,
  onPickupAmountChange: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckDeliveryForm = compose(injectIntl)(CheckDeliveryFormComponent);

CheckDeliveryForm.displayName = 'CheckDeliveryForm';

export default CheckDeliveryForm;
