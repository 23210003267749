import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, PrimaryButton } from '..';

import css from './DamageDepositModal.module.css';

const DamageDepositModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onDamageDeposit,
    damageDepositInProgress,
    damageDepositError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const damageDepositErrorBox = damageDepositError ? (
    <p className={css.modalError}>
      <FormattedMessage id="DamageDepositModal.error" />
    </p>
  ) : null;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.modalWrapper}>
        <p className={css.modalTitle}>
          <FormattedMessage id="DamageDepositModal.title" />
        </p>
        <p className={css.modalMessage}>
          <FormattedMessage id="DamageDepositModal.description" />
        </p>
      </div>
      {damageDepositErrorBox}
      <PrimaryButton
        onClick={onDamageDeposit}
        inProgress={damageDepositInProgress}
      >
        <FormattedMessage id="DamageDepositModal.buttonText" />
      </PrimaryButton>
    </Modal>
  );
};

const { string, func, bool } = PropTypes;

DamageDepositModal.defaultProps = {
  className: null,
  rootClassName: null,
};

DamageDepositModal.propTypes = {
  className: string,
  rootClassName: string,
  onDamageDeposit: func.isRequired,
  damageDepositInProgress: bool.isRequired,
  damageDepositError: propTypes.error,
};

export default DamageDepositModal;
