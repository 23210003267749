import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSupercap.module.css';

const IconSupercap = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="23"
      height="23"
      viewBox="0 0 456.527 456.527"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M455.768,310.97c-5.397-38.312-99.612-46.241-166-43.953c0.251-8.286-0.87-16.753-3.551-25.096 
        c-5.628-17.515-17.125-31.5-31.61-40.514c23.898-43.029,41.406-105.359,25.39-131.664c-29.209-47.972-86.606-66.331-128.2-41.005 
        c-33.324,20.29-2.528,111.288,26.057,171.344c-21.346,12.279-35.041,34.285-37.108,58.307 
        c-49.552,1.636-113.802,19.484-128.081,47.388c-25.585,49.999-11.182,108.514,32.17,130.697 
        c34.075,17.436,93.831-53.34,130.271-108.71c18.068,11.468,40.906,15.129,62.873,8.071c4.717-1.516,9.172-3.467,13.351-5.777 
        c29.13,40.911,83.713,86.965,116.038,82.411C422.983,404.635,462.562,359.192,455.768,310.97z M224.783,294.764 
        c-16.571,5.324-34.321-3.793-39.645-20.364s3.793-34.321,20.364-39.645c16.571-5.325,34.321,3.792,39.645,20.364 
        C250.471,271.69,241.354,289.44,224.783,294.764z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconSupercap.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconSupercap.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconSupercap;
