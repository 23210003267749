import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShip, faKey, faFileContract, faShield, faCalculator, faUserPlus, faUser, faGear, faCircleCheck, faLink, faAnchor, faGlobe, faUserGroup, faPlus, faLifeRing, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import config from '../../../config/config';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faAnchor} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faUser} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faGear} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="VerificationPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('VerificationPage'))}
            name="VerificationPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faCircleCheck} />
            <FormattedMessage id="TopbarDesktop.verificationPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="IntegratePage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('IntegratePage'))}
            name="IntegratePage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faLink} />
            <FormattedMessage id="TopbarDesktop.integrate" />
          </NamedLink>
        </MenuItem>

        {currentUser?.attributes.stripeConnected ? (
        <MenuItem key="HostFacebookPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('HostFacebookPage')
            )}
            name="HostFacebookPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faUserGroup} />
            <FormattedMessage id="TopbarDesktop.facebookGroup" />
          </NamedLink>
        </MenuItem>
        ) :  
        <MenuItem key="NewListingPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('NewListingPage')
            )}
            name="NewListingPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="TopbarDesktop.createFirstListing" />
          </NamedLink>
        </MenuItem> 
        }
        <MenuItem key="HelpPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('HelpPage')
            )}
            name="HelpPage"
          >
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faLifeRing} />
            <FormattedMessage id="TopbarDesktop.getHelp" />
          </NamedLink>
        </MenuItem> 

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faArrowLeft} />
            <FormattedMessage id="TopbarDesktop.logout" />
            <span className={css.versionText}>
              <FormattedMessage id="TopbarDesktop.version" />
            </span>
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const getMenuItems = () => {
    if (config.enableHold) {
      return [
        <MenuItem key="BecomeHost">
          <NamedLink name="BecomeHost" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faShip} />
              <FormattedMessage id="TopbarDesktop.becomeHost" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="HowItWorks">
          <NamedLink name="HowItWorks" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faKey} />
              <FormattedMessage id="TopbarDesktop.howItWorks" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Legal">
          <NamedLink name="TermsOfServicePage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faFileContract} />
              <FormattedMessage id="TopbarDesktop.legal" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Insurance">
          <NamedLink name="InsurancePage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faShield} />
              <FormattedMessage id="TopbarDesktop.insurance" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Calculator">
          <NamedLink name="CalculatorPage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faCalculator} />
              <FormattedMessage id="TopbarDesktop.calculator" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="HelpPage">
          <NamedLink name="HelpPage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faLifeRing} />
              <FormattedMessage id="TopbarDesktop.getHelp" />
            </span>
          </NamedLink>
        </MenuItem>,
      ];
    } else {
      return [
        <MenuItem key="Login">
          <NamedLink name="LoginPage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faUser} />
              <FormattedMessage id="TopbarDesktop.login" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Signup">
          <NamedLink name="SignupPage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faUserPlus} />
              <FormattedMessage id="TopbarDesktop.signup" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="BecomeHost">
          <NamedLink name="BecomeHost" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faShip} />
              <FormattedMessage id="TopbarDesktop.becomeHost" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="HowItWorks">
          <NamedLink name="HowItWorks" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faKey} />
              <FormattedMessage id="TopbarDesktop.howItWorks" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Legal">
          <NamedLink name="TermsOfServicePage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faFileContract} />
              <FormattedMessage id="TopbarDesktop.legal" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Insurance">
          <NamedLink name="InsurancePage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faShield} />
              <FormattedMessage id="TopbarDesktop.insurance" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="Calculator">
          <NamedLink name="CalculatorPage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faCalculator} />
              <FormattedMessage id="TopbarDesktop.calculator" />
            </span>
          </NamedLink>
        </MenuItem>,
        <MenuItem key="HelpPage">
          <NamedLink name="HelpPage" className={css.hamMenu}>
            <span className={css.ham}>
              <FontAwesomeIcon icon={faLifeRing} />
              <FormattedMessage id="TopbarDesktop.getHelp" />
            </span>
          </NamedLink>
        </MenuItem>,
      ];
    }
  };
  
  const loginMenu = !isAuthenticatedOrJustHydrated ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <FontAwesomeIcon icon={faBars} />
      </MenuLabel>
      <MenuContent className={css.loggedOutContent}>
        {getMenuItems().map((item) => item)}
      </MenuContent>
    </Menu>
  ) : null;
  

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {search}

      <NamedLink className={css.hostLink} name="HostPage">
        <span className={css.host}>
          <FormattedMessage id="TopbarDesktop.host" />
        </span>
      </NamedLink>
      <NamedLink className={css.hostLink} name="HowItWorks">
        <span className={css.host}>
          <FormattedMessage id="TopbarDesktop.rent" />
        </span>
      </NamedLink>
      {inboxLink}

      {profileMenu}
      {loginMenu}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
