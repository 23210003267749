import { storableError } from '../util/errors';
import {
  uploadImageToS3API,
} from '../util/api';
import axios from 'axios';

// ================ Action types ================ //

export const UPLOAD_DOCUMENT_REQUEST =
  'app/S3/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS =
  'app/S3/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'app/S3/UPLOAD_DOCUMENT_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/S3/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/S3/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/S3/UPLOAD_IMAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  uploadImageInProgress: false,
  uploadImageErorr: null,
};

export default function S3Reducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadImageInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageInProgress: false,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        uploadImageInProgress: false,
        uploadImageError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const uploadImageRequest = () => ({ type: UPLOAD_IMAGE_REQUEST });
export const uploadImageSuccess = () => ({ type: UPLOAD_IMAGE_SUCCESS });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //


export const uploadImage = image => async (dispatch, getState, sdk) => {
  dispatch(uploadImageRequest());

  // We need to create new FormData and
  // append the image to it, because the
  // SDK doesn't support uploading images
  // outside of listings
  const formData = new FormData();
  formData.append('image', image);

  const headers = {
    'content-type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(uploadImageToS3API, formData, {
      headers,
    });

    dispatch(uploadImageSuccess(response.data));
    return response.data;
  } catch (e) {
    dispatch(uploadImageError(storableError(e)));
  }
};