import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '..';
import { CheckDisputeForm } from '..';
import classNames from 'classnames';

import DisputeIcon from './DisputeIcon';
import css from './CheckDisputeModal.module.css';

const CheckDisputeModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,

    // Check related props
    checkDisputeInProgress,
    checkDisputeError,
    coverageAmount,
    coverageReason,
    isDisputeRequired,
  } = props;

  const classes = classNames('marketplaceModalBaseStyles', rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({
    id: 'Dispute.Later',
  });

  // Modal title, description and icon
  const modalTitle = 
    <FormattedMessage id="Dispute.Title" />;
  const modalDescription = `Need to claim a small amount like gas, cleaning, or a small repair? Input the amount (less than $200) and we'll take care of the rest. Need to hold the entire damage deposit? Check hold! Keep in mind, we take taxes plus a 10% fee to cover payment fees and deposit`;

  const modalIcon =
    <DisputeIcon className={css.modalIcon} />;
  
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {modalIcon}
      <p className="marketplaceModalTitleStyles">{modalTitle}</p>
      <p className="marketplaceModalParagraphStyles">{modalDescription}</p>
      <CheckDisputeForm
        formId="CheckDisputeForm"
        checkDisputeInProgress={checkDisputeInProgress}
        checkDisputeError={checkDisputeError}
        coverageAmount={coverageAmount}
        coverageReason={coverageReason}
        isDisputeRequired={isDisputeRequired}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const { string } = PropTypes;

CheckDisputeModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CheckDisputeModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(CheckDisputeModal);